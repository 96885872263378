import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import { themeStyles, colors, presets, metrics } from '../utils/theme'
import _ from 'lodash'
import { Link } from 'gatsby'


const styles = {
}

const NotFound = ({ data, location }) => {
  return (
    <Layout>
      <div css={[themeStyles.contentWidth, {textAlign: 'center', backgroundColor: colors.lightGrey}]}>
        <div css={[themeStyles.textPadding, {display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}]}>
          <h1>404 Not Found</h1>
          <h3>The page you were looking for does not exist</h3>
          <Link to="/" style={{ marginTop: '20px' }}>Back to Home Page</Link>
          <div style={{ marginBottom: '25vh' }}></div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFound
